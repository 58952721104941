import Parser from 'ninelines-ua-parser';

const parser = new Parser();
let mobile = false;

if (parser.getDevice().type === 'mobile' || parser.getDevice().type === 'tablet' || innerWidth <= 768) {
	mobile = true;
}

export {mobile};

export default {
	$document: $(document),
	$window: $(window),
	$body: $('body'),
	$bodyHtml: $('body, html'),
	$header: $('.header'),

	$newsItem: $('.news__item'),
	$newsExcept: $('.news__except'),
	$newsBody: $('.news__body'),
	$newsBtn: $('.news__item .btn'),

	$navLink: $('.nav__link'),
	$hamburger: $('.js-hamburger'),
	$mobileMenu: $('.js-mobile-menu'),
	$mobileMenuLink: $('.mobile-menu__link'),
};
