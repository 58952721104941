import vars, {mobile} from './vars';

// HAMBURGER MENU
vars.$hamburger.on('click', () => {
	vars.$hamburger.toggleClass('is-active');
	vars.$mobileMenu.toggleClass('is-open');
});

// Main Menu Links with Hash
if (vars.$navLink.length) {
	vars.$navLink.add(vars.$mobileMenuLink).on('click', (event) => {
		let link = event.currentTarget;

		// toggle active state for current link
		vars.$navLink.removeClass('is-active');
		$(link).addClass('is-active');

		if (/#/.test(link.href) && location.pathname === link.pathname) {
			event.preventDefault();

			if (mobile) {
				vars.$hamburger.removeClass('is-active');
				vars.$mobileMenu.removeClass('is-open');
			}

			let headerHeight = innerWidth <= 992 ? 69 : 97;
			const url = $(link).attr('href');
			const hash = url.split('#')[1];
			const id = `#${hash}`;
			const top = $(id).offset().top - headerHeight;

			vars.$bodyHtml.animate({scrollTop: top}, 500, 'swing');
		}
	});
}

// Page load - anchor navigation
if (location.hash) {
	vars.$window.on('load', () => {
		const headerHeight = 101;
		const id = location.hash;
		const top = $(id).offset().top - headerHeight;

		vars.$bodyHtml.animate({scrollTop: top}, 500, 'swing');
	});
}
