import vars from './vars';

if (vars.$newsBtn.length) {
	vars.$newsBtn.on('click', (event) => {
		let text = $(event.currentTarget).text();

		event.preventDefault();
		$(event.currentTarget).text(text === 'Подробнее' ? 'Свернуть' : 'Подробнее');
		$(event.currentTarget)
			.parents('.news__item')
			.find('.news__body')
			.toggleClass('is-opened')
			.parents('.news__item')
			.find('.news__excerpt')
			.toggleClass('is-closed');
	});
}
